<template>
    <a-spin :spinning="isLoading" tip="正在更改变更项...">
	<div>
		<div class="modal-header">
		    <div class="modal-title">
		        <a-icon type="medicine-box" style="margin-right:10px" />
		        <span>{{this.isEdit?l('Edit'):l('Create')}}</span>
		    </div>
		</div>
		<div>
		    <a-config-provider :locale="zh_CN">
				<a-row>
				    <a-col :span="24">
						<a-col :span="8">
							<a-form-item required :label-col="{ span: 5 }" :wrapper-col="{ span: 10 }" :label="l('Name')">
								<a-input v-model="name" class="input"></a-input>
							</a-form-item>
						</a-col>
						<a-col :span="8">
							<a-form-item required :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }"
							    :label="l('audititem.audititemgroupid')">
							    <a-tree-select v-model="auditItemTemplateGroupId" style="width: 100%" :tree-data="treeDataList"
							        :replace-fields="auditTreeReplaceFields"
							        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							        :placeholder="l('Please_select_a_group')" allow-clear
							        tree-default-expand-all>
							    </a-tree-select>
							</a-form-item>
						</a-col>
						
				    </a-col>
					<a-col :span="9">
						<a-col :span="6">
							<a-form-item :label="l('auditTemplate.isWalkAudit')">
							</a-form-item>
						</a-col>
						<a-col :span="5" style="top: 6px;">
							<a-switch :checked-children="l('Yes')" :un-checked-children="l('No')" v-model="isWalkAudit"/>
						</a-col>
						<a-col :span="6">
							<a-form-item :label="l('auditpoint.enable')">
							</a-form-item>
						</a-col>
						<a-col :span="5" style="top: 6px;">
							<a-switch :checked-children="l('Yes')" :un-checked-children="l('No')" v-model="enable"/>
						</a-col>
					</a-col>
					<a-col :span="24">
						<!-- <a-col :span="8">
							<a-card :title="l('220')">
								<div style="height: 400px;width: 100%;overflow:auto;">
									<a-spin :spinning="isTreeLoading">
									<a-tree
									        style="width:100%"
									        :expanded-keys="expandedTreeKeys"
									        :selected-keys="selectedTreeKeys"
									        v-model="checkedTreeKeys"
									        :tree-data="treeData"
									        :replace-fields="treeReplaceFields"
									        @select="onTreeSelect"
									        @expand="onTreeExpand"
									/>
									</a-spin>
								</div>
							</a-card>
						</a-col> -->
						<a-col :span="24">
							<a-card :title="l('221')">
								<div style="height: 400px;">
									<a-row style="margin-bottom:20px;">
										<a-col :span="12">
											<a-button type="primary" @click="createOrEdit('create')">
												<a-icon type="plus" />
												{{l('Create')}}
											</a-button>
											<!-- <a-button type="danger" @click="batchdelete"> -->
											<a-button type="danger" @click="delete1">
												<a-icon type="delete"/>
												{{l('Delete')}}
											</a-button>
										</a-col>
										<a-col :span="12" style="text-align: right;">
											<a-input-search :placeholder="l('please_enter_a_name_to_search')" v-model.trim="filterText" enterButton
												style="width: 200px;" @search="filteronchange"></a-input-search>
										</a-col>
									</a-row>
									<a-spin :spinning="isLoading">
									    <a-table :columns="columns"
									             :data-source="tableData"
									             :customRow="customRow"
									    		 :row-selection="{selectedRowKeys:selectedRowKey,onSelect:onSelect,onSelectAll:SelectAll,columnWidth:'50px' }"
									    		 :rowKey="(record)=>record.id"
									             :scroll="{x:1000,y:310}"
									    		 :pagination="false"
									    		 size="small"
									    >
									    </a-table>
									</a-spin>
								</div>
							</a-card>
						</a-col>
					</a-col>
				</a-row>
			</a-config-provider>
		</div>
		<div class="modal-footer" v-if="!isLook">
		    <a-button @click="close">
		        {{l('Cancel')}}
		    </a-button>
		    <a-button :type="'primary'" @click="save">
		        {{l('Save')}}
		    </a-button>
		</div>
	</div>
</a-spin>
</template>

<script>
	import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
	import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
	import { AuditItemTemplateServiceProxy,AuditTemplateDetailEditDto,AuditItemTemplateEditDto,AuditItemTemplateGroupServiceProxy } from '../../../../shared/service-proxies';
	import { ModalHelper } from '@/shared/helpers';
	import CreatErrorCode from "../audit/audit.vue";
	import {cloneDeepWith} from "lodash";


	
	let _this;
	export default {
		name: 'new-template-point',
		mixins: [ModalComponentBase],
		components: {
		    
		},
		created() {
		    this.fullData(); // 模态框必须,填充数据到data字段
			_this=this;
		    this.AuditItemTemplateServiceProxy = new AuditItemTemplateServiceProxy(this.$apiUrl, this.$api);
			this.AuditItemTemplateGroupServiceProxy = new AuditItemTemplateGroupServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
			console.log(this.id)
			this.getTreeData();
		    // this.getTree();
			if(this.isEdit){
				this.getEdit();

			}
			this.getData()
		},
		data() {
		    return {
				zh_CN,
				id:undefined,
                isLoading: false,//加载中
		        isEdit: false,//是否是编辑
		        isLook: false,//是否是查看
				
				tableData: [],
				columns: [
				    {title: this.l('audititem.audititemtypeid'),width: 50,dataIndex: 'auditItemTypeListName',align: 'center',},
					{title: this.l('Code'),width: 50,dataIndex: 'code',align: 'center',},
				    {title: this.l('audititem.name'), width: 500, dataIndex: 'name', align: 'left',scopedSlots: {customRender: "name"},ellipsis: true},
				],
				
		        entity: [],
				pId: undefined,
				treeDataList: [],
				auditTreeReplaceFields: {
				    title: 'name',
				    key: 'id',
				    value: 'id'
				},
				treeReplaceFields: {
				    title: 'name',
				    key: 'id',
				},
				enable: true,
				isTreeLoading:false,
				isLoading:false,
				checkedTreeKeys: [],
				expandedTreeKeys:[],
				selectedTreeKeys:[],
				treeData:[],
				tree:undefined,
				auditItemCount:undefined,
				name:undefined,
				isWalkAudit:false,
				selectedRow:[],
				selectedRowKey:[],
				//是否禁用
				isdisabled: false,
				selectlist:[],
				itemlist:[],
				savelist:[],
				snlist:[],

				filterText: undefined,
				totalItems: 0, //总数
				// 当前页码
				pageNumber: 1,
				// 共多少页
				totalPages: 1,
				// 条数显示范围
				pagerange: [1, 1],
				// 显示条数
				pageSizeOptions: ["10", "20", "30", "40"],
				request: {
					maxResultCount: 10,
					skipCount: 0
				},
				isclick: true,
				
				auditItemTemplateGroupId: undefined,
				StagingList: []
		    }
		},
		methods: {


			//分页
			onChangePage(page, pageSize) {
				this.selectedRowKey = [];
				this.pageNumber = page;
				this.request.skipCount = (page - 1) * this.request.maxResultCount;
				this.getData();
			},
			//正常分页展示形式，显示（共有 {0} 条）
			// showTotalFun() {
			// 	console.log(this.totalItems);
			//     return this.l(
			//         "GridFooterDisplayText",
			//         this.totalItems
			//     );
			// },
			showSizeChange(current, size) {
				this.selectedRowKey = [];
				this.pageNumber = 1;
				this.request.maxResultCount = size;
				this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
				this.getData();
			},


			//获取数据
			// getTree() {
			//     this.treeData = [];
			//     this.isTreeLoading = true;
			//     this.AuditItemTemplateServiceProxy.getAllData(
			//     		this.id
			//     ).finally(() => {
			//         this.isTreeLoading = false;
			//     }).then(rel => {

			//         if(rel.length <= 0){
			//             this.treeData = [{name: '暂无数据',  id: "null", children: rel,}];
			//         }
			//         else{
			//             this.treeData = [];
			//             rel.forEach(item=>{
			// 				/*if(item.auditItemCount==0) {

			// 				} else {
			// 					item.name = item.name+"("+ item.auditItemCount+")";
			// 				}*/
			//                 this.treeData.push(item);
			//             });
			//         }
			// 		this.expandedTreeKeys.push('null');
			// 		this.recursion(rel);

			//     })
			// },
			//获取编辑数据
			getEdit() {
					this.isLoading = true;
					this.AuditItemTemplateServiceProxy.getForEdit(
					this.id
					).finally(() => {
						this.isLoading = false;
					}).then(res => {
						console.log(res);
						this.entity = res;
						if(res.name==null){
							this.enable;
						}else {
							this.enable = res.enable;
						}
						if (res.auditItemTemplateGroupId == 0) {
						    this.auditItemTemplateGroupId = undefined;
						} else {
						    this.auditItemTemplateGroupId = res.auditItemTemplateGroupId;
						}
						this.name=res.name;
						this.isWalkAudit=res.isWalkAudit;
					})
			},
			
			//获取数据
			getData(){
				this.isLoading=true;
				this.AuditItemTemplateServiceProxy.getAuditItem(
						this.id,
						undefined,
						undefined,
						this.request.maxResultCount,
						this.request.skipCount
				)
						.finally(()=>{
					this.isLoading = false;
				}).then(val=>{
					console.log(val,"val");
					this.tableData=val;
					this.tableData.forEach(item=>{
						// this.selectedRowKey.push(item.id);
						item.checked=true;
					})
					this.StagingList = this.tableData;
					this.totalItems = val.totalCount;

				})
			},
			// recursion(rel) {
			// 	console.log(rel);
			//     if (rel.length > 0) {
			//         for (let i = 0; i < rel.length; i++) {
			//             console.log(rel[i].id)
			// 			console.log(rel[i].children);
			//             this.expandedTreeKeys.push(rel[i].id)
			//             // this.recursion(rel[i].children);
			//         }
			//     } else {
			//         return
			//     }
			// },
			// onTreeExpand(expandedKeys, record) {
			//     console.log(expandedKeys,"expandedKeys");
			//     console.log(record,"record");
			//     this.expandedTreeKeys = expandedKeys;
			// },
			//架构选择
			// onTreeSelect(selectedKeys, info) {
			//     console.log(selectedKeys, info);
			//     if (selectedKeys.length > 0) {
			//         this.selectedTreeKeys = [];
			//         if(isNaN(selectedKeys[0])){
			//             this.selectedTreeKeys = selectedKeys;
			
			//             this.getData();
			//         }
			//         else{
			//             this.selected = selectedKeys[0];
			//             this.selectedTreeKeys = selectedKeys;
			
			//             this.getData();
			
			//         }
			
			//     }
			// },
			//新增
			createOrEdit(cases) {
				// if (this.selectedTreeKeys.length<=0) {
				// 	abp.message.info(this.l('select_auditpoint'));
				// 	return;
				// }
				this.filterText = undefined;
				this.tableData = this.StagingList;
				if(this.tableData.length>0){
					this.savelist=[];
					this.tableData.forEach(res=>{
						this.savelist.push(res.id);
					})
				}else {
					this.savelist=[];
				}

				ModalHelper.create(CreatErrorCode, {
					isEdit: false,
					id: undefined,
					selectedRowKey:this.savelist,
					//resultlist:cloneDeepWith(this.tableData),
				},{width:1200}).subscribe(res => {
					// debugger;
					console.log(res,"回调");
					var select = res.length;
					if (select>0) {
						console.log(this.tableData,"tableData11");
						// this.tableData=[];
						this.selectlist=[];
						this.savelist=[];
						this.snlist=[];
						this.itemlist.forEach(k=>{
							this.tableData.push(k);
						});

						this.selectlist=res;

						this.selectlist.forEach(item=>{
							// debugger;
							item.isdata=false;
							item.i=true;
							for(var x=0;(x<this.tableData.length&&this.tableData.length!=0);x++){
								// debugger;
								if(item.id==this.tableData[x].id){
									item.i=false;
									break;
								}
							}
						});
						// debugger;
						this.selectlist.forEach(item=>{
							if(item.i==true){
								this.tableData.push(item);
								this.savelist.push(item.id);
								this.snlist.push(item.sn);
							}
						})
						
						this.StagingList = this.tableData;

					}

				})
			},
			//搜索框点击事件
			filteronchange() {
				this.tableData = [];
				this.StagingList.forEach(item=>{
					if(item.name.includes(this.filterText)){
						this.tableData.push(item);
					}
				})
				this.pageNumber = 1;
				this.request.skipCount = 0;
			},
			//行选择
			customRow: (record) => {
			    return {
			        on: {
			            click: () => {
			                _this.onSelect(record)
			            }
			        }
			    }
				
			},
			//多选
			SelectAll(record, rows, crows) {
			  if (record) {
			      //更改表格数据勾选状态
			      this.tableData.map(item => {
			          item.checked = true;
			      });
			      rows.forEach((item, index) => {
			          //判断是否存在
			          let u = this.selectedRowKey.find(t => t == item.id);
			          if (!u) {
			              this.selectedRowKey.push(item.id);
			              this.selectedRow.push(item);
			          }
			      });
			  } else {
			      //更改表格数据勾选状态
			      this.tableData.forEach((item, index) => {
			          item.checked = false;
			          this.selectedRowKey = [];
			          this.selectedRow = [];
			      });
			  }
			},
			// 重写复选框点击事件(不可删除)
			onSelect(record) {
			    record.checked = !record.checked;
			    if (record.checked) {
			        this.selectedRowKey.push(record.id);
					this.selectedRow.push(record);
			    } else {
			        this.selectedRowKey = _.difference(_this.selectedRowKey, [record.id]);
					this.selectedRow = _.difference(_this.selectedRow, [record]);
			    }
			},
			save() {
                this.isLoading = true;
				if (this.name ==null||this.name=="") {
					abp.message.info(this.l("input_audittemplate"));
					return;
				}

				if (this.auditItemTemplateGroupId == undefined) {
					abp.message.info(this.l("Please_select_a_group"));
					return;
				}

				var entity1=new AuditItemTemplateEditDto();
				if(this.isEdit){
					entity1.id=this.id;
				}
				entity1.auditItemTemplateGroupId = this.auditItemTemplateGroupId;
				entity1.name=this.name;
				entity1.isWalkAudit=this.isWalkAudit;
				entity1.enable=this.enable;

				var list=[];
				console.log(entity1)
				for(var i=0;i<this.StagingList.length;i++){
					list.push(this.StagingList[i].id);
				}

				var resultentity={
					auditItemTemplate:entity1,
					auditItemTemplateDetailIdList:list
				}

				console.log(resultentity)
				if(this.isclick) {
					this.isclick = false;
					this.AuditItemTemplateServiceProxy.createOrUpdate(
							resultentity
					).finally(() => {
						this.close()
					}).then(res => {
						this.isclick = true;
                        this.isLoading = true;
						this.success(true)
					})
				}
			
			},

			//删除数组中的元素
			arr(arr,res){
				for(var i = 0; i < arr.length; i++) {
					if(arr[i].id == res) {
						arr.splice(i, 1);
						break;
					}
				}
			},

			arr2(arr,res){
				for(var i = 0; i < arr.length; i++) {
					if(arr[i] == res) {
						arr.splice(i, 1);
						break;
					}
				}
			},


			delete1(){
				if (this.selectedRow.length <= 0) {
					abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
					return;
				}
				
				var data = this.selectedRow;
				for(let i = 0;i<this.tableData.length;i++) {
					for(let l = 0;l<data.length;l++) {
						if (this.tableData[i].id === data[l].id) {
						    this.tableData.splice(i, 1);
						}
					}
				}
				for(let i = 0;i<this.StagingList.length;i++) {
					for(let l = 0;l<data.length;l++) {
						if (this.StagingList[i].id === data[l].id) {
						    this.StagingList.splice(i, 1);
						}
					}
				}
			},

			getTreeData() {
			    this.isLoading = true;
			    this.AuditItemTemplateGroupServiceProxy.getPaged(
			        undefined
			    ).finally(() => {
				    this.isLoading = false;
				})
				.then((res) => {
				    this.treeDataList = JSON.parse(JSON.stringify(res));
				})
			},
		}
	}
</script>

<style>
	.input {
		width: 200px;
	}
</style>
