import { render, staticRenderFns } from "./create-or-edit-btn-info.vue?vue&type=template&id=15ca95bc&scoped=true"
import script from "./create-or-edit-btn-info.vue?vue&type=script&lang=js"
export * from "./create-or-edit-btn-info.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15ca95bc",
  null
  
)

export default component.exports