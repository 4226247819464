<template>
    <div>
        <a-config-provider :locale="zh_CN">
            <a-card>
                <a-row style="margin-bottom: 20px;">
                    <a-col :span="10">
                        <a-button type="primary" @click="createOrEdit('create')" v-if="isGranted('data-maintenance_create')">
                            <a-icon type="plus"/>
                            {{l('Create')}}
                        </a-button>
                        <a-button type="danger" @click="Delate()" v-if="isGranted('data-maintenance_delete')">
                            <a-icon type="delete"/>
                            {{l('Delete')}}
                        </a-button>
						<a-button @click="Export" v-if="isGranted('data-maintenance_export')">
							<a-icon type="export" />
							{{ l('Export') }}
						</a-button>
						<a-dropdown v-if="isGranted('data-maintenance_import')">
						    <a-menu slot="overlay" @click="handleMenuClick">
						        <a-menu-item key="1">
						            <a-icon type="download" />
						            {{l("ImportToExcelTemplate")}}
						        </a-menu-item>
						        <a-menu-item key="2">
						            <a-icon type="import" />
						            {{l("ImportFromExcel")}}
						        </a-menu-item>
						    </a-menu>
						    <a-button style="margin-left: 8px">
						        <a-icon type="import" />
						        {{l("import")}}
						    </a-button>
						</a-dropdown>
                    </a-col>

                    <a-col :span="14" class="d-flex justify-content-end">
							<a-input-search :placeholder="l('please_enter_a_name_to_search')" v-model.trim="filterText" enterButton
											style="width: 200px;margin-left: 10px;" @search="filteronchange"

							></a-input-search>
                    </a-col>
                </a-row>
                <a-spin :spinning="isLoading">
                    <a-table :columns="columns"
                             :data-source="tableData"
                             :customRow="customRow"
                             :row-selection="{ selectedRowKeys:selectedRowKey,onSelect:onSelect,onSelectAll:SelectAll,columnWidth:'50px' }"
                             :rowKey="(record)=>record.id"
                             :pagination="false"
                             style="max-height: 600px;overflow: auto;"
                             :scroll="{x:800,y:scroll_x}"
							 size="small"
                    >
						<span slot="value" slot-scope="text,record">
							<a @click.stop="DataSettings(record)">{{l('Data_settings')}}</a>
						</span>
						<span slot="isDockingData" slot-scope="text">
							<span v-if="text"><a-icon type='check' style="color: #1890ff;"/></span>
							<span v-else><a-icon type='close'/></span>
						</span>
						<span slot="action" slot-scope="text,record">
							<a @click.stop="createOrEditMachineSupplier(record)" v-if="isGranted('data-maintenance_edit')">
								<a-icon type="edit"/>{{l('Parameter_configuration')}}
							</a>
						</span>
                    </a-table>
                </a-spin>
				<a-pagination class="pagination" :total="totalItems" showSizeChanger showQuickJumper
					:defaultPageSize="request.maxResultCount" :pageSizeOptions="pageSizeOptions"
					:show-total="total => `共有 ${totalItems} 条`" @change="onChangePage"
					@showSizeChange="showSizeChange" />
            </a-card>
        </a-config-provider>
    </div>
</template>
<script>
	import { AppComponentBase } from "@/shared/component-base";
	import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
	import { ModalHelper } from '@/shared/helpers';
	import { KanBanServiceProxy } from '../../../shared/service-proxies';
	import CreateOrEdit from "./createOredit/createOredit";
	import DataSettings from "./Data-settings/Data-settings";
	import importExcelComponent from "./import-excel/import-excel";
	import XLSX from 'xlsx';
	import XLSXS from 'xlsx-style';
	import FileSaver from "file-saver";

	let _this;
	export default {
	    name: "Data-maintenance",
	    mixins: [AppComponentBase],
	    components: {
	        CreateOrEdit,
			DataSettings,
			importExcelComponent
	    },
	    created() {
	        _this = this;
	        this.KanBanServiceProxy = new KanBanServiceProxy(this.$apiUrl, this.$api);
	    },
	    mounted() {
	        this.getData()
	    },
	    data() {
	        return {
	            zh_CN,
	            isEdit: false,
	            isLook: false,

	            filterText: undefined,
	            // 总数
	            totalItems: 0,
	            // 当前页码
	            pageNumber: 1,
	            // 共多少页
	            totalPages: 1,
	            // 条数显示范围
	            pagerange: [1, 1],
	            // 显示条数
	            pageSizeOptions: ["10", "20", "30", "40"],
	            request: {maxResultCount: 20, skipCount: 0},

	            tableData: [],
	            columns: [
	                {
	                    title: this.l('Code'),
	                    width: 100,
	                    dataIndex: 'code',
	                    align: 'center',
						ellipsis: true,
	                },
	                {title: this.l('Name'), width: 100, dataIndex: 'name', align: 'center',},
                    {
                        title: this.l('Data'),
                        width: 200,
                        dataIndex: 'value',
                        align: 'center',
                        ellipsis: true,
						scopedSlots: {
							customRender: "value"
						},
                    },
					{title: this.l('Remark'), width: 200, dataIndex: 'remark', align: 'center',},
					{
						title: this.l('Whether_to_connect_data'), 
						width: 140, 
						dataIndex: 'isDockingData', 
						align: 'center',
						scopedSlots: {customRender: "isDockingData"}
					},
	                {
	                	title: this.l('Actions'),
	                	width: 120,
	                	dataIndex: 'action',
	                	align: 'center',
	                	scopedSlots: {
	                		customRender: "action"
	                	},
						fixed: 'right',
	                },
	            ],
	            selectedRowKey: [], // 选择的数据Id
	            selectedRow: [],//选择的整行数据
	            isLoading: false,//加载中
	            dictionaryListApi: undefined,
	            EqmamIssueStatusApi: undefined,//接口
				defaultTop:40,
	        }
	    },
	    methods: {
			//重置
			Reset(){
				this.selectedRowKey = [];
				this.getData();
			},
	        onChangeInput() {
	            this.getData();
	        },
	        createOrEdit(cases) {
	            this.isEdit = false;
	            this.isLook = false;
	            if (cases === 'create') {
	                ModalHelper.create(CreateOrEdit, {
	                    isEdit: this.isEdit,
	                    isLook: this.isLook,
	                }, {width: 700}).subscribe(res => {
	                    console.log(res)
	                    if (res) {
	                        this.getData();
	                    }
	                })
	            } else if (cases === 'edit') {
					if (this.selectedRowKey.length > 1) {
					    abp.message.info('请选择一条数据编辑')
					    return;
					}
	                if (this.selectedRowKey.length === 0) {
	                    abp.message.info('请选择一条数据');
	                    return;
	                } else {

	                    this.isEdit = true;

	                    ModalHelper.create(CreateOrEdit, {
	                        isEdit: this.isEdit,
	                        isLook: this.isLook,
	                        id: this.selectedRowKey[0] ? this.selectedRowKey : undefined
	                    }, {width: 500}).subscribe(res => {
	                        console.log(res)
	                        if (res) {
	                            this.getData();
	                        }
	                    })
	                }
	            }

	        },
			createOrEditMachineSupplier(record) {
				this.isEdit = true;
				ModalHelper.create(CreateOrEdit, {
				    isEdit: this.isEdit,
				    isLook: this.isLook,
				    id: record.id
				}, {width: 700}).subscribe(res => {
				    console.log(res)
				    if (res) {
				        this.getData();
				    }
				})
			},
			DataSettings(record) {
				console.log(record)
				ModalHelper.create(DataSettings, {
				    id: record.id
				}, {width: 1000}).subscribe(res => {
				    if (res) {
				        this.getData();
				    }
				})
			},
	        Delate() {
				if (this.selectedRowKey[0] <= 0 || this.selectedRowKey[0] == null) {
					abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
					return;
				}
                for (var i=0;i<this.selectedRow.length;i++){
                    if (this.selectedRow[i].progress == 0) {
                        return abp.message.error(this.l('the_starting_value'))
                        break;
                    } else if (this.selectedRow[i].progress == 100) {
                        return abp.message.error(this.l('the_end_value'))
                        break;
                    }
                }

	                abp.message.confirm(this.l('this_progress_value'), this.l('confirm_deletion'),
	                    result => {
	                        if (result) {
	                            this.isLoading = true;
	                            _this.KanBanServiceProxy.batchDelete(_this.selectedRowKey).finally(() => {
	                                this.isLoading = false;
	                            }).then(res => {
	                                    _this.getData()
	                                }
	                            )
	                        }
	                    });

	        },
	        getData() {
				this.selectedRowKey = [];
	            this.isLoading = true;
	            this.KanBanServiceProxy.getPaged(
				this.filterText ? this.filterText : undefined,
				undefined,
				this.request.maxResultCount,
				this.request.skipCount,
	            ).finally(() => {
	                this.isLoading = false;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
	            }).then(res => {
					console.log(res);
	                this.tableData = res.items;
					this.totalItems = res.totalCount;
					this.totalPages = Math.ceil(
						res.totalCount / this.request.maxResultCount
					);
					this.pagerange = [
						(this.pageNumber - 1) * this.request.maxResultCount + 1,
						this.pageNumber * this.request.maxResultCount,
					];
	            })
	        },
	        //行选择
	        customRow: (record) => {
	            return {
	                on: {
	                    click: () => {
	                        _this.onSelect(record)
	                    }
	                }
	            }

	        },
	        //多选
	        SelectAll(record, rows, crows) {
	            if (record) {
	                //更改表格数据勾选状态
	                this.tableData.map(item => {
	                    item.checked = true;
	                });
	                rows.forEach((item, index) => {
	                    //判断是否存在
	                    let u = this.selectedRowKey.find(t => t == item.id);
	                    if (!u) {
	                        this.selectedRowKey = [...this.selectedRowKey, item.id];
	                    }
	                    let a=this.selectedRow.find((x=>x==item));
	                    if(!a){
                            this.selectedRow = [...this.selectedRow, item];
                        }
	                });
	            } else {
	                //更改表格数据勾选状态
	                this.tableData.forEach((item, index) => {
	                    item.checked = false;
	                    this.selectedRowKey = _.difference(this.selectedRowKey, [item.id]);
                        this.selectedRow = _.difference(this.selectedRow, [item]);
	                });
	            }
	        },
	        // 重写复选框点击事件(不可删除)
	        onSelect(record) {
	            //debugger;
	            record.checked = !record.checked;
	            if (record.checked) {
	                this.selectedRowKey.push(record.id);
	                this.selectedRow.push(record);
	            } else {
	                this.selectedRowKey = _.difference(_this.selectedRowKey, [record.id]);
                    this.selectedRow = _.difference(_this.selectedRow, [record]);
	            }
	        },
			//分页
			onChangePage(page, pageSize) {
				this.pageNumber = page;
				this.request.skipCount = (page - 1) * this.request.maxResultCount;
				this.getData();
			},
			showSizeChange(current, size) {
				this.pageNumber = 1;
				this.request.maxResultCount = size;
				this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
				this.getData();
			},
	        //搜索框点击事件
	        filteronchange() {
	            this.pageNumber = 1;
	            this.request.skipCount = 0;
	            this.getData();
	        },
			
			//导出
			Export() {
				if (this.selectedRowKey[0] <= 0 || this.selectedRowKey[0] == null) {
					abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
					return;
				}
				if (this.selectedRowKey.length > 1) {
					abp.message.warn(this.l("OnlySelectOne"));
					return;
				}
				let paramDesc = JSON.parse(this.selectedRow[0].paramDesc);
				let valueList = JSON.parse(this.selectedRow[0].value);
				let expData = [];
				valueList.map((item, index) => {
					let data = {};
				    paramDesc.map((param, number) => {
						data[param.text] = item[param.paramName];
				    })
					expData.push(data)
				})
				let workbook = XLSX.utils.book_new();
				let sheet = XLSX.utils.json_to_sheet(expData);
				XLSX.utils.book_append_sheet(workbook, sheet, this.selectedRow[0].name);
				XLSX.writeFile(workbook, this.selectedRow[0].name + '.xlsx');	
			},
			
			//下载模板和导入
			handleMenuClick(rel) {
				if (this.selectedRowKey[0] <= 0 || this.selectedRowKey[0] == null) {
					abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
					return;
				}
				if (this.selectedRowKey.length > 1) {
					abp.message.warn(this.l("OnlySelectOne"));
					return;
				}
				if(rel.key === '1') {
					this.templateDownload();
				}else {
					ModalHelper.create(
                        importExcelComponent,
                        {
							id: JSON.parse(this.selectedRowKey[0])
						},
                        {
                            width: 400,
                            height: 400
                        }
                    ).subscribe((res) => {
                        //console.log(res);
                        if (res) {
                            //刷新数据
                            this.getData();
                        }
                    });
				}
			},
			
			templateDownload() {
				let expData = [];
				let header = [];
				let paramDesc = JSON.parse(this.selectedRow[0].paramDesc);
				for(let item of paramDesc) {
					header.push(item.text)
				}
				let workbook = XLSX.utils.book_new();
				let sheet = XLSX.utils.json_to_sheet(expData,{header:header});
				XLSX.utils.book_append_sheet(workbook, sheet, '导入模板');
				XLSX.writeFile(workbook, this.selectedRow[0].name + '导入模板.xlsx');
			}
	    }
	}
</script>

<style>
	.pagination {
		margin: 10px auto;
		text-align: right;
	}
</style>
