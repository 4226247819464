import { render, staticRenderFns } from "./host-module.vue?vue&type=template&id=dab4258c&scoped=true"
import script from "./host-module.vue?vue&type=script&lang=js"
export * from "./host-module.vue?vue&type=script&lang=js"
import style0 from "./host-module.vue?vue&type=style&index=0&id=dab4258c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dab4258c",
  null
  
)

export default component.exports