import { render, staticRenderFns } from "./Job-number-rules.vue?vue&type=template&id=c8754d5a&scoped=true"
import script from "./Job-number-rules.vue?vue&type=script&lang=js"
export * from "./Job-number-rules.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8754d5a",
  null
  
)

export default component.exports