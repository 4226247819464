<template>
	<div>
		<a-config-provider :locale="zh_CN">
			<a-card>
				<a-row style="margin-bottom: 20px;">
					<a-col :span="24">
						<a-month-picker v-model="date" @change="onChange3" :allowClear="false"
						    style="width: 120px;margin-right: 10px;" />
					</a-col>
				</a-row>
				<a-spin :spinning="isLoading">
					<div id="data" style="height: 0px;text-align: center;font-size: 2rem;line-height: 100px;"></div>
					<div style="height: 720px;width: 100%;text-align: left" id="mainScrapCode">

					</div>
				</a-spin>
			</a-card>
		</a-config-provider>
	</div>
</template>
<script>
	import {
		AppComponentBase
	} from "@/shared/component-base";
	import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
	import {
		KanBanServiceProxy
	} from '../../../shared/service-proxies';
	import moment from "moment";
	import * as echarts from 'echarts';

	let _this;
	export default {
		name: "Departmental-hidden",
		mixins: [AppComponentBase],
		components: {

		},
		created() {
			_this = this;
			this.KanBanServiceProxy = new KanBanServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
			this.function();
		},
		data() {
			return {
				zh_CN,
				isLoading: true, //加载中
				date: undefined,
				nowTime: [],
				
				chartData: [],
				data: undefined,
				departmentList: [],
				fakeData: []
			}
		},
		computed: {
			// 指定图表的配置项和数据
			option() {
				return {
					title: {
						text: this.l('部门隐患月度发现数量'),
						subtext: '',
						x: 'center',
						textStyle: {
							fontSize: 26,
						},
					},
					tooltip: {
						trigger: 'axis',
					},
					legend: {
						// data: [this.l('FindNum'), this.l('CloseNum'), this.l('CloseRate')],
						top: '97%',
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						name: '',
						data: this.departmentList,
						splitLine: {
							show: true
						}
					}, ],
					yAxis: {
						type: 'value',
						name: this.l('Count'),
						min: 0,
						axisLabel: {
							formatter: '{value}',
						},
					},
					series: this.chartData,
				}
			}
		},
		methods: {
			moment,
			function() {
				this.date = moment(new Date());
				this.getDataMaintenance();
			},
			getData() {
				this.isLoading = true;
				this.KanBanServiceProxy.getDeptIssueMonthQtyForSafetyHazards(
					this.date
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					this.data = res;
					this.departmentList = res.name;
					this.chartsData();
				})
			},

			chartsData() {
				let number = {
					name: this.l('实际排查数量'),
					type: 'bar',
					label: {
					    show: true,
					    // position: 'top',
						formatter: function (params) {
						    if (params.value > 0) {
						        return params.value;
						    }else {
						        return '';
						    }
						}
					}
				};
				let target = {
					name: this.l('目标'),
					type: 'line',
					label: {
						show: true,
						textBorderWidth: '0'
					}
				};
				
				let targetAllList = [];
				for(let item of this.fakeData) {
					if(item.code === "Departmental-hidden-dangers") {
						targetAllList = JSON.parse(item.value)
					}
				}
				let targetList = [];
				for(let item of targetAllList) {
					if(item.date === moment(this.date).format('YYYY-MM')) {
						targetList.push(item)
					}
				}
				let targetData = [];
				for(let item of this.departmentList) {
					let isContain = targetList.find(items => items.departmentName == item);
					if(isContain) {
						let targetNumber = Number(isContain.depPeople) * Number(isContain.target);
						if(String(targetNumber).indexOf('.') > -1) {
							targetNumber = Math.ceil(targetNumber);
						}
						targetData.push(targetNumber);
					}else {
						targetData.push(0);
					}
				}
				number.data = this.data.issueCount;
				target.data = targetData;
				this.chartData = [];
				this.chartData.push(number);
				this.chartData.push(target);
				this.charts();
			},
			
			//维护的数据
			getDataMaintenance() {
				this.KanBanServiceProxy.getAll(
					
				).finally(() => {
				    
				}).then(res => {
					this.fakeData = res;
					this.getData();
				})
			},

			charts() {
				this.myeChart = echarts.init(document.getElementById("mainScrapCode"))

				this.myeChart.clear();
				
				document.getElementById("data").style.height = "0px";
				document.getElementById("data").innerHTML = "";
				
				// 使用刚指定的配置项和数据显示图表。
				document.getElementById("mainScrapCode").style.height = "720px";
				this.myeChart.setOption(this.option);
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			onChange3(date, time) {
			    this.date = date;
			    this.getData();
			}
		}
	}
</script>

<style>
</style>
