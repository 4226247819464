import { render, staticRenderFns } from "./create-or-edit-module.vue?vue&type=template&id=46c183b0&scoped=true"
import script from "./create-or-edit-module.vue?vue&type=script&lang=js"
export * from "./create-or-edit-module.vue?vue&type=script&lang=js"
import style0 from "./create-or-edit-module.vue?vue&type=style&index=0&id=46c183b0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46c183b0",
  null
  
)

export default component.exports