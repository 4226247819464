<template>
	<div>
	    <a-config-provider :locale="zh_CN">
			<a-card>
				<a-row>
					<!-- <a-col :span="24" style="text-align: right;">
						<a-range-picker @change="DiscoverytimeChange" :placeholder="[l('StartTime'), l('WEEndDate')]" v-model="nowTime"
						 style=" width: 250px;text-align: center;" />
					</a-col> -->
					<a-col :span="5">
						<div style="width: 100%;height: 55px;border-right: 1px solid #d3d3d3;">
							<div style="margin-left: 8%;">
								<a-icon type="close-circle" />
								<span>
									{{l('The_number_of_unclosed_issues')}}
								</span>
								<!-- <span style="color: red;font-size: 25px;">
									{{notCloseIssueCount}}
								</span> -->
							</div>
							<div style="margin-left: 8%;">
								<h1 style="color: red;display:inline;">
									{{notCloseIssueCount}}
								</h1>
								<span style="margin-left: 20%;margin-bottom: 10px">
									{{l('Total_number_of_issues') + '：'}}
								</span>
								<span style="color: red;">
									{{issueTotalCount}}
								</span>
							</div>
						</div>
					</a-col>
					<a-col :span="5">
						<div style="width: 100%;height: 55px;border-right: 1px solid #d3d3d3;">
							<div style="margin-left: 8%;">
								<a-icon type="clock-circle" />
								<span>
									{{l('The_number_of_timeout_issues')}}
								</span>
							</div>
							<div style="margin-left: 8%;">
								<h1 style="color: red;">
									{{overtimeIssueCount}}
								</h1>
							</div>
						</div>
					</a-col>
					<a-col :span="5">
						<div style="width: 100%;height: 55px;border-right: 1px solid #d3d3d3;">
							<div style="margin-left: 8%;">
								<a-icon type="environment" />
								<span>
									{{l('The_area_with_the_most_problems')}}
								</span>
							</div>
							<div style="margin-left: 8%;">
								<div style="margin-top: 8px;">
									<span style="color: red;font-size: 20px;">
										{{mostAuditPointIssueName}}
									</span>
									<span style="margin-left: 15%;">
										{{l('Issue_Number') + '：' + mostAuditPointIssueCount}}
									</span>
								</div>
							</div>
						</div>
					</a-col>
					<a-col :span="5">
						<div style="width: 100%;height: 55px;border-right: 1px solid #d3d3d3;">
							<div style="margin-left: 8%;">
								<a-icon type="tool" />
								<span>
									{{l('Audit_completion_rate')}}
								</span>
							</div>
							<div style="margin-left: 8%;">
								<h1 style="color: green;display:inline;">
									{{auditTaskCompletionRate + '%'}}
								</h1>
								<span style="margin-left: 20%;">
									{{l('Totals')}}
								</span>
								<span style="color: green;">
									{{auditTaskTotalCount}}
								</span>
								<span>
									{{l('Totaltasks')}}
								</span>
							</div>
						</div>
					</a-col>
					<a-col :span="4">
						<div style="margin-left: 8%;text-align: left;">
							<a-icon type="funnel-plot" />
							<span>
								{{l('DateRange')}}
							</span>
						</div>
						<a-range-picker @change="DiscoverytimeChange" :placeholder="[l('StartTime'), l('WEEndDate')]" v-model="nowTime"
						 style=" width: 200px;text-align: center;margin-left: 8%;margin-top: 2px;" />
					</a-col>
				</a-row>
				<a-row style="margin-top: 20px;">
					<a-col :span="24">
						<div style="width: 60%;height: 380px;float: left;">
							<div style="width: 60%;height: 380px;float: left;position: absolute;">
								<!-- <div style="width: 100%;height: 39px;color: #97a7b7;font-size: 24px;">
									{{l('Issue_Statistics')}}
									<a-switch :checked-children="l('month')" :un-checked-children="l('week')" default-checked v-model="istime" @change="timeChange"/>
								</div> -->
								<div style="width: 98%;height: 1px;border-bottom: 1px solid #d3d3d3;top: 39px;position: relative;">
									
								</div>
								<div style="width: 100%;height: 380px;" id="mainScrapCode">
									
								</div>
							</div>
						</div>
						<div style="width: 40%;height: 380px;float: left;">
							<div style="width: 40%;height: 380px;float: left;position: absolute;">
								<!-- <div style="width: 100%;height: 40px;border-bottom: 1px solid #d3d3d3;color: #97a7b7;font-size: 24px;">
									{{l('IssueArea') + ' TOP10'}}
								</div> -->
								<div style="width: 98%;height: 1px;border-bottom: 1px solid #d3d3d3;top: 39px;position: relative;">
									
								</div>
								<div style="width: 100%;height: 380px;" id="TOPchart">
								</div>
							</div>
						</div>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="24">
						<div style="width: 60%;height: 350px;float: left;">
							<div style="width: 60%;height: 350px;float: left;position: absolute;">
								<!-- <div style="width: 100%;height: 40px;color: #97a7b7;font-size: 24px;">
									{{l('TaskStatistics')}}
								</div> -->
								<div style="width: 98%;height: 1px;border-bottom: 1px solid #d3d3d3;top: 39px;position: relative;">
									
								</div>
								<div style="width: 100%;height: 350px;" id="taskchart">
									
								</div>
							</div>
						</div>
						<div style="width: 40%;height: 350px;float: left;">
							<div style="width: 40%;height: 350px;float: left;position: absolute;">
								<!-- <div style="width: 100%;height: 40px;border-bottom: 1px solid #d3d3d3;color: #97a7b7;font-size: 24px;">
									{{l('Proportion_of_Issue_classification')}}
								</div> -->
								<div style="width: 98%;height: 1px;border-bottom: 1px solid #d3d3d3;top: 39px;position: relative;">
									
								</div>
								<div style="width: 100%;height: 350px;" id="Piechart">
								</div>
							</div>
						</div>
					</a-col>
				</a-row>
			</a-card>
		</a-config-provider>
	</div>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import {
		DashboardServiceProxy,IssueServiceProxy,ReportServiceProxy
	} from '../../../shared/service-proxies';
import * as echarts from 'echarts';
import moment from "moment";
import { ModalHelper } from '@/shared/helpers';
import IssueListView from "../../underlying-data/problem-management/problem-management.vue";
	
let _this;

export default {
	name: 'dashboard',
	mixins: [AppComponentBase],
	components: {
		IssueListView
	},
	created() {
		_this = this;
		this.DashboardServiceProxy = new DashboardServiceProxy(this.$apiUrl, this.$api);
		this.ReportServiceProxy = new ReportServiceProxy(this.$apiUrl, this.$api);
		this.IssueServiceProxy = new IssueServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.nowDate();
		
	},
	data() {
		return {
			zh_CN,
			
			startDate: undefined,
			endDate: undefined,
			nowTime: [],
			
			notCloseIssueCount: 0,
			issueTotalCount: 0,
			overtimeIssueCount: 0,
			mostAuditPointIssueCount: 0,
			mostAuditPointIssueName: undefined,
			auditTaskCompletionRate: 0,
			auditTaskTotalCount: 0,
			
			istime: true,
			time: 1,
			typeCode: 1,
			xname: '月',
			month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
			issuedata: [],
			chartData: [],
			chartWeek: undefined,
			
			issueTopTenList: [],
			issueTopTenData: [],
			
			pieData: [],
			
			orgId: undefined,
			taskmonth: [],
			taskData: []
		};
	},
	computed: {
		option1() {
			return {
				title: {
				    text: this.l('Issue_Statistics'),
					y: '0',
					x: '-5px',
					textStyle: {
					  fontSize: '24px',
					  fontWeight: 'normal',
					  color: '#97a7b7'
					}
				},
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					left: '5%',
					right: '165',
					top: 100,
					bottom: 40
				},
				legend: {
					orient: 'vertical',
					right: '15',
					top: '40%'
				},
				calculable: true,
				xAxis: [{
					type: 'category',
					name: '   ' + this.xname,
					data: this.chartWeek,
					splitLine: {
						show: true
					}
				}, ],
				yAxis: [{
						type: 'value',
						name: this.l('Count'),
						min: 0,
						axisLabel: {
							formatter: '{value}',
						},
					},
					{
						type: 'value',
						name: this.l('Issue_CloseRate') + '(%)',
						min: 0,
						max: 100,
						axisLabel: {
							formatter: '{value}%',
						},
					},
				],
				series: this.chartData,
			}
		},
		option2() {
			return {
				title: {
				    text: this.l('Proportion_of_Issue_classification'),
					y: '0',
					x: '-5px',
					textStyle: {
					  fontSize: '24px',
					  fontWeight: 'normal',
					  color: '#97a7b7'
					}
				},
				tooltip: {
				  trigger: 'item',
				  formatter: function (a) {
				      return (
				          "问题分类占比" +
				          "<br/>" +
				          a.name +
				          "：" +
				          a.value +
				          " (" +
				          a.percent.toFixed(1) +
				          "%)"
				      );
				  }
				},
				legend: {
				  orient: 'vertical',
				  left: 'right',
				  top: '35%',
				  formatter: function (params) {
				  	let name;
				  	if(params.length > 20) {
				  		name = params.substring(0, 20) + '\n' + params.substring(20, params.length);
				  	}else {
				  		name = params;
				  	}
				      return name;
				  }
				},
				series: [
				  {
				    type: 'pie',
				    radius: '65%',
					center: ['30%', '60%'],
				    data: this.pieData,
				    emphasis: {
				      itemStyle: {
				        shadowBlur: 10,
				        shadowOffsetX: 0,
				        shadowColor: 'rgba(0, 0, 0, 0.5)'
				      }
				    },
					label: {
						formatter: function (params) {
							let name;
							if(params.name.length > 20) {
								name = params.name.substring(0, 20) + '\n' + params.name.substring(20, params.name.length);
							}else {
								name = params.name;
							}
						    return name;
						}
					}
				  },
				  {
				    type: 'pie',
				    radius: '65%',
					center: ['30%', '60%'],
				    data: this.pieData,
					label: {
						normal: {
							show: true,
							position: 'inside',
							color: '#000',
							textStyle: {
								fontSize: 10
							},
							formatter: function (params) {
							    return Math.round(params.percent * 10) / 10 + '%';
							}
						}
					}
				  }
				]
			}
		},
		option3() {
			return {
				title: {
				    text: this.l('TaskStatistics'),
					y: '0',
					x: '-5px',
					textStyle: {
					  fontSize: '24px',
					  fontWeight: 'normal',
					  color: '#97a7b7'
					}
				},
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					left: '5%',
					right: '165',
					top: 100,
					bottom: 40
				},
				legend: {
					orient: 'vertical',
					right: '15',
					top: '40%'
				},
				calculable: true,
				xAxis: [{
					type: 'category',
					name: '   ' + this.l('month'),
					data: this.taskmonth,
					splitLine: {
						show: true
					}
				}, ],
				yAxis: [{
						type: 'value',
						name: this.l('Count'),
						min: 0,
						axisLabel: {
							formatter: '{value}',
						},
					},
					{
						type: 'value',
						name: this.l('FinishRate') + '(%)',
						min: 0,
						max: 100,
						axisLabel: {
							formatter: '{value}%',
						},
						splitLine:{
						    show:true,
						    lineStyle:{
						        type:'dashed'
						    }
						}
					},
				],
				series: this.taskData,
			}
		},
		option4() {
			return {
				title: {
				    text: this.l('IssueArea') + ' TOP10',
					y: '0',
					x: '-5px',
					textStyle: {
					  fontSize: '24px',
					  fontWeight: 'normal',
					  color: '#97a7b7'
					}
				},
				tooltip: {
				  trigger: 'axis',
				  axisPointer: {
				    type: 'shadow',
				  },
				},
				grid: {
				  top: 100,
				  bottom: this.issueTopTenList.length == 1 ? '16%' : '12%',
				  containLabel: true,
				},
				legend: {
					orient: 'vertical',
					top: '50'
				},
				calculable: true,
				xAxis: {
				  position: 'top',
				  minInterval: 1,
				  type: 'value',
				  boundaryGap: [0, 0.01],
				},
				yAxis: {
				  type: 'category',
				  data: this.issueTopTenList,
				  inverse: true,
				},
				series: [
				  {
				    name: this.l('FeedbackIssueNum'),
				    type: 'bar',
				    barMaxWidth: 50, //最大宽度
				    data: this.issueTopTenData,
					label: {
					    show: true,
					},
				  },
				],
			}
		},
	},
	methods: {
		//获取当前年
		nowDate() {
			let startTime = new Date().getFullYear() + '-01-01';
			let endTime = new Date().getFullYear() + '-12-31';
			this.nowTime = [moment(startTime), moment(endTime)];
			this.startDate = moment(startTime);
			this.endDate = moment(endTime);
			this.getData();
			this.getIssueData();
			this.getIssueTopTenData();
			this.gettaskData();
			this.getIssueSummaryData();
		},
		getData() {
			let data = {
				startTime: this.startDate,
				endTime: this.endDate
			};
			this.isLoading = true;
			this.DashboardServiceProxy.issueAndAuditTaskRelevant(
				data
			).finally(() => {
				this.isLoading = false;
			}).then(res => {
				this.notCloseIssueCount = res.notCloseIssueCount;
				this.issueTotalCount= res.issueTotalCount;
				this.overtimeIssueCount= res.overtimeIssueCount;
				this.mostAuditPointIssueCount= res.mostAuditPointIssueCount;
				this.mostAuditPointIssueName= res.mostAuditPointIssueName;
				this.auditTaskCompletionRate= Math.round((res.auditTaskCompletionRate * 100 *10)) / 10;
				this.auditTaskTotalCount= res.auditTaskTotalCount;
			})
		},
		
		getIssueData() {
			let Year = moment(this.startDate).format('yyyy')
			this.isLoading = true;
			this.ReportServiceProxy.getMonthClosePercent(
				this.startDate,
				this.endDate).finally(() => {
				this.isLoading = false;
			}).then(res => {
		
				this.issuedata = res;
				
				let discoveriesNumber = {
					name: this.l('NewlyAdded'),
					type: 'bar',
					label: {
					    show: true,
					    position: 'top',
						formatter: function (params) {
						    if (params.value > 0) {
						        return params.value;
						    }else {
						        return '';
						    }
						}
					}
				};
				let closeNumber = {
					name: this.l('Close'),
					type: 'bar',
					label: {
					    show: true,
					    position: 'top',
						formatter: function (params) {
						    if (params.value > 0) {
						        return params.value;
						    }else {
						        return '';
						    }
						}
					}
				};
				let closeRate = {
					name: this.l('Issue_CloseRate'),
					type: 'line',
					yAxisIndex : 1,
					label: {
						show: true,
						textBorderWidth: '0',
						formatter: function(data){
							if(data.value>0) {
								return data.value + '%'
							}else {
								return ""
							}
						}
					}
				};
				let discoveriesdata = [];
				let closedata = [];
				let closeRatedata = [];
				this.chartWeek = [];
				for (let i = 0; i < this.issuedata.length; i++) {
					discoveriesdata.push(this.issuedata[i].problemCount);
					closedata.push(this.issuedata[i].closeCount);
					closeRatedata.push(this.issuedata[i].closePercent);
					this.chartWeek.push(this.issuedata[i].month);
				}
				discoveriesNumber.data = discoveriesdata;
				closeNumber.data = closedata;
				closeRate.data = closeRatedata;
				this.chartData = [];
				this.chartData.push(discoveriesNumber);
				this.chartData.push(closeNumber);
				this.chartData.push(closeRate);
				this.charts();
			})
		},
		
		charts() {
			this.myeChart = echarts.init(document.getElementById("mainScrapCode"))
			
			this.myeChart.off('click');
			this.myeChart.clear();
			
			this.myeChart.setOption(this.option1);
			this.myeChart.on('click', function (params) {
			    let year = moment(params.name).format('YYYY');
				let month = moment(params.name).format('MM');
			    let startTime = new Date(year, month -1,1);
			    let endTime = new Date(startTime.getFullYear(), startTime.getMonth() + 1,0);
			    ModalHelper.create(IssueListView, {
			        startTime: startTime,
			    	endTime: endTime
			    }, { width: 1600 }).subscribe(res => {
			        
			    })
			});
			
			this.myeChart.resize();
			window.addEventListener('resize', () => {
				this.myeChart.resize();
			})
		},
		
		//TOP10
		getIssueTopTenData() {
			let data = {
				startTime: this.startDate,
				endTime: this.endDate
			};
			this.isLoading = true;
			this.DashboardServiceProxy.auditPointIssueTOPTen(
				data
			).finally(() => {
				this.isLoading = false;
			}).then(res => {
				this.issueTopTenList = [];
				this.issueTopTenData = [];
				res.forEach((item, index) => {
				  //循环赋值数据
				  this.issueTopTenList.push(item.auditPointName);
				  let data = {};
				  data.value = item.auditPointIssueCount;
				  data.id = item.auditPointId;
				  this.issueTopTenData.push(data);
				});
				this.TOPCharts();
			})
		},
		
		TOPCharts() {
			this.myeChart = echarts.init(document.getElementById("TOPchart"))
			
			this.myeChart.off('click');
			
			this.myeChart.clear();
			this.myeChart.setOption(this.option4);
			this.myeChart.on('click', function (params) {
				console.log(params.data.id)
			    ModalHelper.create(IssueListView, {
			        show1: true,
					auditPointId: params.data.id,
					startTime: moment(_this.startDate).format('YYYY-MM-DD'),
					endTime: moment(_this.endDate).format('YYYY-MM-DD')
			    }, { width: 1600 }).subscribe(res => {
			        
			    })
			});
			
			this.myeChart.resize();
			window.addEventListener('resize', () => {
				this.myeChart.resize();
			})
		},
		
		//审核任务
		gettaskData() {
			this.isLoading = true;
			this.ReportServiceProxy.getAuditTaskHistogram(
				this.startDate,
				this.endDate,
				this.orgId
			).finally(() => {
				this.isLoading = false;
			}).then(res => {
				let discoveriesNumber = {
					name: this.l('Plan'),
					type: 'bar',
					label: {
					    show: true,
					    position: 'top',
						formatter: function (params) {
						    if (params.value > 0) {
						        return params.value;
						    }else {
						        return '';
						    }
						}
					}
				};
				let closeNumber = {
					name: this.l('Finish'),
					type: 'bar',
					label: {
					    show: true,
					    position: 'top',
						formatter: function (params) {
						    if (params.value > 0) {
						        return params.value;
						    }else {
						        return '';
						    }
						}
					}
				};
				let closeRate = {
					name: this.l('FinishRate'),
					type: 'line',
					yAxisIndex : 1,
					label: {
						show: true,
						textBorderWidth: '0',
						formatter: function(data){
							if(data.value>0) {
								return data.value + '%'
							}else {
								return ""
							}
						}
					}
				};
				this.taskmonth = [];
				let discoveriesdata = [];
				let closedata = [];
				let closeRatedata = [];
				for (let i = 0; i < res.length; i++) {
					discoveriesdata.push(res[i].auditTaskPlanCount);
					closedata.push(res[i].auditTaskCompleteCount);
					closeRatedata.push(res[i].completePercent);
					this.taskmonth.push(res[i].month)
				}
				discoveriesNumber.data = discoveriesdata;
				closeNumber.data = closedata;
				closeRate.data = closeRatedata;
				this.taskData = [];
				this.taskData.push(discoveriesNumber);
				this.taskData.push(closeNumber);
				this.taskData.push(closeRate);
				this.taskCharts();
			})
		},
		
		//问题概括
		getIssueSummaryData() {
			this.isLoading = true;
			this.DashboardServiceProxy.getIssueForIssueType(
				this.startDate,
				this.endDate
			).finally(() => {
				this.isLoading = false;
			}).then(res => {
				this.pieData = [];
				let piedata = res.sort(function(a, b) {
				    return b.issueTotalCount - a.issueTotalCount
				});
				if(piedata.length > 5) {
					for(let i = 0; i < 5 ;i++) {
						let data = {};
						data.value = piedata[i].issueTotalCount;
						data.name = piedata[i].name;
						this.pieData.push(data);
					}
					let number = 0;
					for(let a = 5; a < piedata.length ;a++) {
						let data = {};
						data.value = piedata[a].issueTotalCount;
						data.name = piedata[a].name;
						number = number + piedata[a].issueTotalCount;
					}
					let isConform = this.pieData.some(item => item.name === '其他' || item.name === 'Other' || item.name === 'other');
					if(isConform) {
						for(let l in this.pieData) {
							if(this.pieData[l].name === '其他' || this.pieData[l].name === 'Other' || this.pieData[l].name === 'other') {
								this.pieData[l].value += number;
							}
						}
					}else {
						let other = {
							name : this.l('Other'),
							value: number
						};
						this.pieData.push(other);
					}
				}else {
					for(let i in piedata) {
						let data = {};
						data.value = piedata[i].issueTotalCount;
						data.name = piedata[i].name;
						this.pieData.push(data);
					}
				}
				this.pieCharts();
				// this.completionCharts();
			})
		},
		
		pieCharts() {
			this.myeChart = echarts.init(document.getElementById("Piechart"))
		
			this.myeChart.clear();
			
			this.myeChart.setOption(this.option2);
			
			this.myeChart.resize();
			window.addEventListener('resize', () => {
				this.myeChart.resize();
			})
		},
		
		taskCharts() {
			this.myeChart = echarts.init(document.getElementById("taskchart"))
		
			this.myeChart.clear();
			
			this.myeChart.setOption(this.option3);
			
			this.myeChart.resize();
			window.addEventListener('resize', () => {
				this.myeChart.resize();
			})
		},
		
		//日期选择
		DiscoverytimeChange(date, dateString) {
			this.startDate = date[0];
			this.endDate = date[1];
			this.isClear = true;
			this.getData();
			this.getIssueData();
			this.getIssueTopTenData();
			this.gettaskData();
			this.getIssueSummaryData();
		},
		
		//选择月或周
		timeChange() {
			if(this.istime) {
				this.time = 1;
			}else {
				this.time = 2;
			}
			this.getIssueData();
		}
	},
};
</script>

<style lang="less" scoped>
@import './dashboard.less';
</style>
