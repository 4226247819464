import { render, staticRenderFns } from "./leave-card-overtime-detailed-page.vue?vue&type=template&id=d3ca0932&scoped=true"
import script from "./leave-card-overtime-detailed-page.vue?vue&type=script&lang=js"
export * from "./leave-card-overtime-detailed-page.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3ca0932",
  null
  
)

export default component.exports