<template>
	<div>
		<div class="modal-header">
		    <div class="modal-title">
		        <a-icon type="medicine-box" style="margin-right:10px" />
		        <span>{{this.isEdit?l('Edit'):l('Create')}}{{l('221')}}</span>
		    </div>
		</div>
		<div>
		    <a-config-provider :locale="zh_CN">
				<a-row>
				    <a-col :span="8">
						<a-card :title="l('audit_item_grouping')">
							<div style="height: 488px;width: 100%;overflow:auto;">
								<a-spin :spinning="isTreeLoading">
									<a-tree
											style="width:100%"
											:expanded-keys="expandedTreeKeys"
											:selected-keys="selectedTreeKeys"
											v-model="checkedTreeKeys"
											:tree-data="treeData"
											:replace-fields="treeReplaceFields"
											@select="onTreeSelect"
											@expand="onTreeExpand"
									/>
								</a-spin>
							</div>
						</a-card>
					</a-col>
					<a-col :span="16">
						<a-card :title="l('221')">
							<a-row style="margin-bottom:10px;">
								<a-col :span="24" style="text-align: right;">
									<a-tree-select
									        style="width: 200px"
									        :tree-data="workProcessData"
									        :replace-fields="treeReplace"
									        :dropdown-style="{ maxHeight: '400px', overflow: 'auto',width: '300px' }"
									        :placeholder="l('please_choose_the_type')"
									        allow-clear
									        tree-default-expand-all
											:dropdownMatchSelectWidth="false"
											@change="workProcessClassChange"
											@search="filteronchange"
									>
									</a-tree-select>
									<a-input-search :placeholder="l('please_enter_the_audit_item_to_search')" v-model.trim="filterText" enterButton
										style="width: 200px;margin-left: 10px;" @search="filteronchange"></a-input-search>
								</a-col>
							</a-row>
								<a-spin :spinning="isLoading">
								    <a-table :columns="columns"
								             :data-source="tableData"
								             :customRow="customRow"
											 :row-selection="{selectedRowKeys:selectedRowKey,onSelect:onSelect,onSelectAll:SelectAll,columnWidth:'50px' }"
											 :rowKey="(record)=>record.id"
								             :scroll="{x:1000,y:354}"
											 :pagination="false"
											 size="small"
											 @change="handleTableChange"
								    >
								    </a-table>
								</a-spin>
								<a-pagination
								        class="pagination"
								        :total="totalItems"
								        showSizeChanger
								        showQuickJumper
										:defaultPageSize="request.maxResultCount"
										:pageSizeOptions="pageSizeOptions"
								        @change="onChangePage"
								        @showSizeChange="showSizeChange"/>
						</a-card>
					</a-col>
				</a-row>
			</a-config-provider>
		</div>
		<div class="modal-footer" v-if="!isLook">
		    <a-button @click="close">
		        {{l('Cancel')}}
		    </a-button>
		    <a-button :type="'primary'" @click="save">
		        {{l('Save')}}
		    </a-button>
		</div>
	</div>
</template>

<script>
	import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
	import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
	import { AuditItemGroupServiceProxy,AudititemServiceProxy,AuditItemtypeServiceProxy} from '../../../../shared/service-proxies';
	
	let _this;
	export default {
		name: 'audit',
		mixins: [ModalComponentBase],
		components: {
		    
		},
		created() {
		    this.fullData(); // 模态框必须,填充数据到data字段
			_this = this;
			this.AuditItemGroupServiceProxy = new AuditItemGroupServiceProxy(this.$apiUrl, this.$api);
			this.AudititemServiceProxy = new AudititemServiceProxy(this.$apiUrl, this.$api);
			this.AuditItemtypeServiceProxy = new AuditItemtypeServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
			this.getTree();
			this.getData();
			this.getWorkProcessData();
		},
		data() {
		    return {
				zh_CN,
				isLook: false,//是否是查看
				filterText: undefined,
				totalItems: 0,//总数
				// 当前页码
				pageNumber: 1,
				// 共多少页
				totalPages: 1,
				// 条数显示范围
				pagerange: [1, 1],
				// 显示条数
				pageSizeOptions: ["10", "20", "50", "100", "500"],
				request: {maxResultCount: 20, skipCount: 0},
				
				
				tableData: [],
				columns: [
				    {title: this.l('audititem.audititemtypeid'),width: 150,dataIndex: 'auditItemTypeListName',align: 'center',},
					{title: this.l('Code'),width: 50,dataIndex: 'code',align: 'center',},
				    {title: this.l('audititem.name'), width: 500, dataIndex: 'name', align: 'left',scopedSlots: {customRender: "name"},ellipsis: true},
				],
				isLoading: false,//加载中
				isTreeLoading: false,//加载中
				expandedTreeKeys: [],
				selectedTreeKeys: [],
				treeData: [],
				treeReplaceFields: {
				    title: 'name',
				    key: 'id',
				    code: 'code'
				},
				checkedTreeKeys: [],
				selectedRowKey: [], // 选择的数据Id
				selectedRow: [],//选择的整行数据
				workProcessData: [],
				treeReplace: {
				    title: 'name',
				    key: 'id',
				    value: 'id'
				},
				resultlist:[],
		    }
		},
		methods: {
			//获取数据
			getTree() {
				this.treeData = [];
				this.isTreeLoading = true;
				this.AuditItemGroupServiceProxy.getPaged(
				).finally(() => {
				    this.isTreeLoading = false;
				}).then(rel => {
					console.log(rel);
					if(rel.length <= 0){
					    this.treeData = [{name: '审核项', code: '', id: "null", children: rel,}];
					}
					else{
						this.treeData = [];
					    rel.forEach(item=>{
					        this.treeData.push(item)
					    });
					
					    console.log(rel)
					}
					
					this.expandedTreeKeys.push('null');
					this.recursion(rel);
				})
			},
			//获取审核项类型
			getWorkProcessData() {
				this.isLoading = true;
				this.AuditItemtypeServiceProxy.getAllDate(
				this.filterText ? this.filterText : undefined,
				undefined,
				this.request.maxResultCount,
				this.request.skipCount
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					console.log(res);
				    this.workProcessData = JSON.parse(JSON.stringify(res));
				})
			},
			workProcessClassChange(value){
				console.log(value);
				this.workProcessClass = value;
				this.getData();
			},
			//搜索框点击事件
			filteronchange() {
				this.pageNumber = 1;
				this.request.skipCount = 0;
				this.getData();
			},
			//排序
			handleTableChange(pagination, filters, sorter) {
			    console.log(sorter);
			    if (sorter.order != undefined){
			        this.Sorting = sorter.field + " " + sorter.order.replace("end","");
			    }
			    else{
			        this.Sorting = undefined;
			    }
			    this.getData();
			},
			recursion(rel) {
			    if (rel.length > 0) {
			        for (let i = 0; i < rel.length; i++) {
			            // console.log(rel[i].id)
			            this.expandedTreeKeys.push(rel[i].id)
			            this.recursion(rel[i].children);
			        }
			    } else {
			        return
			    }
			},
			onTreeExpand(expandedKeys, record) {
			    this.expandedTreeKeys = expandedKeys;
			},
			//审核项分组选择
			onTreeSelect(selectedKeys, info) {
			    if (selectedKeys.length > 0) {
			        this.workProcessClass = undefined;
			        this.selectedTreeKeys = [];
			        this.treeNodeCode = [];
			        if(isNaN(selectedKeys[0])){
			            console.log(selectedKeys, info);
			            this.selectedTreeKeys = selectedKeys;
			            this.workProcessClass = info.selectedNodes[0].data.props.code;
			            console.log(this.workProcessClass);
			            //获取物料信息
			            this.getData();
			            // this.selectedRowKeys = [];
			            // this.selectedRows = [];
			        }
			        else{
			            console.log(selectedKeys[0], info);
						this.selected = selectedKeys[0];
			            this.selectedTreeKeys = selectedKeys;
			            this.treeNodeCode = selectedKeys;
			            if (this.selectedTreeKeys[0] == 'null' || this.treeNodeCode == 'W01' || this.treeNodeCode == 'W02' || this.treeNodeCode == 'W03' || this.treeNodeCode == 'W04') {
			                this.groupAct = true;
			            } else {
			                this.groupAct = false;
			            }
			            //获取物料信息
			            this.getData();
			            // this.selectedRowKeys = [];
			            // this.selectedRows = [];
			        }
			    
			    }
			},
			
			
			//获取审核项数据
			getData() {
				this.selectedRowKeys = [];
				this.isLoading = true;
				this.AudititemServiceProxy.getPagedEnable(
				this.workProcessClass,
						this.selected,
				this.filterText ? this.filterText : undefined,
				this.Sorting,
				this.request.maxResultCount,
				this.request.skipCount
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					console.log(res);
					this.tableData = res.items;
					
					this.totalItems = res.totalCount;
					this.totalPages = Math.ceil(
					    res.totalCount / this.request.maxResultCount
					);
					this.pagerange = [
					    (this.pageNumber - 1) * this.request.maxResultCount + 1,
					    this.pageNumber * this.request.maxResultCount,
					];
				})
			},
			//行选择
			customRow: (record) => {
			    return {
			        on: {
			            click: () => {
			                _this.onSelect(record)
			            }
			        }
			    }
				
			},
			//多选
			SelectAll(record, rows, crows) {
			  if (record) {
			      //更改表格数据勾选状态
			      this.tableData.map(item => {
			          item.checked = true;
			      });
			      rows.forEach((item, index) => {
			          //判断是否存在
			          let u = this.selectedRowKey.find(t => t == item.id);
			          if (!u) {
			              this.selectedRowKey.push(item.id);
			              this.selectedRow.push(item);
			          }
			      });
			  } else {
			      //更改表格数据勾选状态
			      this.tableData.forEach((item, index) => {
			          item.checked = false;
			          this.selectedRowKey = [];
			          this.selectedRow = [];
			      });
			  }
			},
			// 重写复选框点击事件(不可删除)
			onSelect(record) {
			    record.checked = !record.checked;
			    if (record.checked) {
			        this.selectedRowKey.push(record.id);
					this.selectedRow.push(record);
			    } else {
			        this.selectedRowKey = _.difference(_this.selectedRowKey, [record.id]);
					this.selectedRow = _.difference(_this.selectedRow, [record]);
			    }
			},
			//分页
			onChangePage(page, pageSize) {
			    // this.selectedRowKey = [];
			    this.pageNumber = page;
			    this.request.skipCount = (page - 1) * this.request.maxResultCount;
			    this.getData();
			},
			//正常分页展示形式，显示（共有 {0} 条）
			// showTotalFun() {
			// 	console.log(this.totalItems);
			//     return this.l(
			//         "GridFooterDisplayText",
			//         this.totalItems
			//     );
			// },
			showSizeChange(current, size) {
			    //this.selectedRowKey = [];
			    this.pageNumber = 1;
			    this.request.maxResultCount = size;
			    this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
			    this.getData();
			},
			save() {
				var counts = this.selectedRowKey.length;
				if (counts == 0) {
				    abp.message.info('请选择一个审核项');
				    return;
				}
				if (this.selectedRowKey && this.selectedRow) {
				    var suerid = this.selectedRowKey.length === 0 ? null : this.selectedRowKey;

				    this.selectedRow.forEach(res=>{
				    	this.selectedRowKey.forEach(g=>{
				    		if(res.id==g){
				    			this.resultlist.push(res);
							}
						})
					})

					for(var i=0;i<this.resultlist.length;i++){
						this.resultlist[i].auditItemId=this.resultlist[i].id;
						this.resultlist[i].isdata=false;
					}
					this.success(this.resultlist);
				    // this.success({code: this.selectedRows[0].code, name: this.selectedRows[0].name,id: this.selectedRows[0].id});
				} else {
				    this.success(true);
				}
			},
		}
	}
</script>

<style>
</style>
