<template>
	<div>
		<div class="modal-header">
		    <div class="modal-title">
		        <a-icon type="medicine-box" style="margin-right:10px" />
		        <span>{{this.isEdit?l('Edit'):l('Create')}}</span>
		    </div>
		</div>
		<div>
		    <a-config-provider :locale="zh_CN">
				<a-row>
				    <a-col :span="24">
						<a-col :span="12">
							<a-form-item required :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }" :label="l('Name')">
								<a-input style="width: 100%" v-model="name" class="input"></a-input>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item required :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }"
							    :label="l('audititem.audititemgroupid')">
							    <a-tree-select v-model="auditTemplateGroupId" style="width: 100%" :tree-data="treeDataList"
							        :replace-fields="auditTreeReplaceFields"
							        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							        :placeholder="l('Please_select_a_group')" allow-clear
							        tree-default-expand-all>
							    </a-tree-select>
							</a-form-item>
						</a-col>

                        

				    </a-col>
					<a-col :span="12">
						<a-col :span="6">
							<a-form-item :label="l('auditTemplate.isWalkAudit')">
							</a-form-item>
						</a-col>
						<a-col :span="5" style="top: 6px;">
							<a-switch :checked-children="l('Yes')" :un-checked-children="l('No')" v-model="isWalkAudit"/>
						</a-col>
						<a-col :span="3">
							<a-form-item :label="l('auditpoint.enable')">
							</a-form-item>
						</a-col>
						<a-col :span="5" style="top: 6px;">
							<a-switch :checked-children="l('Yes')" :un-checked-children="l('No')" v-model="enable"/>
						</a-col>
					</a-col>
                    <a-col :span="12">
                        <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }"
                            :label="l('Barcode')">
                            <a-select mode="multiple" :filterOption="filterOption"
                            v-model="selectbarcode"
                        style="width: 100%;text-align: left;margin-right: 10px;" :maxTagCount = "1"
                        :placeholder="l('Barcode_selection')" @change="barcodeChange" allow-clear>
                            <a-select-option v-for="item in barCodeList" :key="item.id"
                            :title="item.name">
                            {{ item.name + "(" + item.code + ")" }}
                        </a-select-option>
                    </a-select>
                        </a-form-item>
                    </a-col>
					<a-col :span="24">
						<a-col :span="8">
							<a-card :title="l('220')">
								<div style="height: 400px;width: 100%;overflow:auto;">
									<a-spin :spinning="isTreeLoading">
									<a-tree
									        style="width:100%"
									        :expanded-keys="expandedTreeKeys"
									        :selected-keys="selectedTreeKeys"
									        v-model="checkedTreeKeys"
									        :tree-data="treeData"
									        :replace-fields="treeReplaceFields"
									        @select="onTreeSelect"
									        @expand="onTreeExpand"
									/>
									</a-spin>
								</div>
							</a-card>
						</a-col>
						<a-col :span="16">
							<a-card :title="l('221')">
								<div style="height: 400px;">
									<a-row style="margin-bottom:20px;">
										<a-col :span="12">
											<a-button type="primary" @click="createOrEdit('create')">
												<a-icon type="plus" />
												{{l('Create')}}
											</a-button>
											<!-- <a-button type="danger" @click="batchdelete"> -->
											<a-button type="danger" @click="delete1">
												<a-icon type="delete"/>
												{{l('Delete')}}
											</a-button>
										</a-col>
										<a-col :span="12" style="text-align: right;">
											<a-input-search :placeholder="l('please_enter_a_name_to_search')" v-model.trim="filterText" enterButton
												style="width: 200px;" @search="filteronchange"></a-input-search>
										</a-col>
									</a-row>
									<a-spin :spinning="isLoading">
									    <a-table :columns="columns"
									             :data-source="tableData"
									             :customRow="customRow"
									    		 :row-selection="{selectedRowKeys:selectedRowKey,onSelect:onSelect,onSelectAll:SelectAll,columnWidth:'50px' }"
									    		 :rowKey="(record)=>record.auditItemId"
									             :scroll="{x:1000,y:305}"
									    		 :pagination="false"
									    		 size="small"
									    >
									    </a-table>
									</a-spin>
								</div>
							</a-card>
						</a-col>
					</a-col>
				</a-row>
			</a-config-provider>
		</div>
		<div class="modal-footer" v-if="!isLook">
		    <a-button @click="close">
		        {{l('Cancel')}}
		    </a-button>
		    <a-button :type="'primary'" @click="save">
		        {{l('Save')}}
		    </a-button>
		</div>
	</div>
</template>

<script>
	import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
	import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
	import { AuditTemplateServiceProxy,AuditTemplateDetailEditDto,AuditTemplateEditoDto,AuditTemplateGroupServiceProxy,TemplateBarCodeServiceProxy } from '../../../../shared/service-proxies';
	import { ModalHelper } from '@/shared/helpers';
	import CreatErrorCode from "../audit/audit.vue";
	import * as _ from 'lodash';


	
	let _this;
	export default {
		name: 'new-template',
		mixins: [ModalComponentBase],
		components: {
		    
		},
		created() {
		    this.fullData(); // 模态框必须,填充数据到data字段
			_this=this;
		    this.AuditTemplateServiceProxy = new AuditTemplateServiceProxy(this.$apiUrl, this.$api);
			this.AuditTemplateGroupServiceProxy = new AuditTemplateGroupServiceProxy(this.$apiUrl, this.$api);
            this.TemplateBarCodeServiceProxy = new TemplateBarCodeServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
            this.getbarcodeList();
		    this.getTree();
			this.getTreeData();
			if(this.isEdit){
				this.getEdit();
			}
		},
		data() {
		    return {
				zh_CN,
				id:undefined,
		        isEdit: false,//是否是编辑
		        isLook: false,//是否是查看
				
				tableData: [],
				columns: [
				    {title: this.l('audititem.audititemtypeid'),width: 50,dataIndex: 'auditItemTypeListName',align: 'center',},
					{title: this.l('Code'),width: 50,dataIndex: 'code',align: 'center',},
				    {title: this.l('audititem.name'), width: 500, dataIndex: 'name', align: 'left',scopedSlots: {customRender: "name"},ellipsis: true},
				],
				
		        entity: [],
				pId: undefined,
				treeDataList: [],
				auditTreeReplaceFields: {
				    title: 'name',
				    key: 'id',
				    value: 'id'
				},
				treeReplaceFields: {
				    title: 'name',
				    key: 'id',
				},
				enable: true,
				isTreeLoading:false,
				isLoading:false,
				checkedTreeKeys: [],
				expandedTreeKeys:[],
				selectedTreeKeys:[],
				treeData:[],
				tree:undefined,
				auditItemCount:undefined,
				name:undefined,
				isWalkAudit:false,
				selectedRow:[],
				filterText:undefined,
				selectedRowKey:[],
				//是否禁用
				isdisabled: false,
				selectlist:[],
				itemlist:[],
				savelist:[],
				snlist:[],
				date: [],
				list: [],
				Staging: [],
				isclick: true,
				
				auditTemplateGroupId: undefined,

                barCodeList:[],
                selectbarcode:[],

		    }
		},
		methods: {
			//获取数据
			getTree() {
			    this.treeData = [];
			    this.isTreeLoading = true;
			    this.AuditTemplateServiceProxy.getAllData(
			    		this.id
			    ).finally(() => {
			        this.isTreeLoading = false;
			    }).then(rel => {

			        if(rel.length <= 0){
			            this.treeData = [{name: this.l('NoData'),  id: "null", children: rel,}];
			        }
			        else{
			            this.treeData = [];
			            rel.forEach(item=>{
							/*if(item.auditItemCount==0) {

							} else {
								item.name = item.name+"("+ item.auditItemCount+")";
							}*/
			                this.treeData.push(item);
			            });
			        }
					this.expandedTreeKeys.push('null');
					this.recursion(rel);

			    })
			},

            barcodeChange(value) {
				this.selectbarcode = value;
				this.getData();
			},

            //获取数据
            getbarcodeList() {
                this.isTreeLoading = true;
                this.TemplateBarCodeServiceProxy.getAll().finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this.barCodeList = res;
                })
            },

			//获取编辑数据
			getEdit() {
					this.isLoading = true;
					this.AuditTemplateServiceProxy.getForEdit(
					this.id
					).finally(() => {
						this.isLoading = false;
					}).then(res => {
						console.log(res);
						this.entity = res;
						if(res.name==null){
							this.enable;
						}else {
							this.enable = res.enable;
						}
						if (res.auditTemplateGroupId == 0) {
						    this.auditTemplateGroupId = undefined;
						} else {
						    this.auditTemplateGroupId = res.auditTemplateGroupId;
						}
						this.name=res.name;
						this.isWalkAudit=res.isWalkAudit;
                        this.selectbarcode = res.barCodeList;
					})
			},
			
			//获取数据
			getData(){
				this.isLoading=true;
				this.AuditTemplateServiceProxy.getitem(
						this.id,
						this.selectedTreeKeys.length>0?this.selectedTreeKeys[0]:undefined,
						this.filterText
				)
						.finally(()=>{
					this.isLoading = false;
				}).then(val=>{
					console.log(val,"val");
					for(let i = 0;i<this.Staging.length;i++) {
						if(this.Staging[i].pid == this.selectedTreeKeys[0]) {
							val.push(this.Staging[i]);
						}
					}
					this.tableData=val;
					this.itemlist=[];
					this.itemlist=val;
					this.itemlist.forEach(item=>{
						item.isdata=true;
					})
					this.tableData.forEach(res=>{
						res.isdata=true;
					})
				})
			},
			recursion(rel) {
				console.log(rel);
			    if (rel.length > 0) {
			        for (let i = 0; i < rel.length; i++) {
			            console.log(rel[i].id)
						console.log(rel[i].children);
			            this.expandedTreeKeys.push(rel[i].id)
			            // this.recursion(rel[i].children);
			        }
			    } else {
			        return
			    }
			},
			onTreeExpand(expandedKeys, record) {
			    console.log(expandedKeys,"expandedKeys");
			    console.log(record,"record");
			    this.expandedTreeKeys = expandedKeys;
			},
			//架构选择
			onTreeSelect(selectedKeys) {
				this.selectedTreeKeys = selectedKeys;
			    this.getData();
			},
			//新增
			createOrEdit(cases) {
				if (this.selectedTreeKeys.length<=0) {
					abp.message.info(this.l('select_auditpoint'));
					return;
				}

				if(this.tableData.length>0){
					this.savelist=[];
					this.tableData.forEach(res=>{
						this.savelist.push(res.auditItemId);
					})
				}else {
					this.savelist=[];
				}

				ModalHelper.create(CreatErrorCode, {
					isEdit: false,
					id: undefined,
					selectedRowKey:this.savelist,
					//resultlist:cloneDeepWith(this.tableData),
				},{width:1200}).subscribe(res => {
					// debugger;
					console.log(res,"回调");
					console.log(this.selectedTreeKeys[0]);
					
					var select = res.length;
					if (select>0) {
						console.log(this.tableData,"tableData11");
						this.tableData=[];
						this.selectlist=[];
						this.savelist=[];
						this.snlist=[];
						console.log(this.tableData,"tableData22");
						this.itemlist.forEach(k=>{
							this.tableData.push(k);
						});

						console.log(this.tableData,"tableData333");
						this.selectlist=res;

						this.selectlist.forEach(item=>{
							// debugger;
							item.isdata=false;
							item.i=true;
							for(var x=0;(x<this.tableData.length&&this.tableData.length!=0);x++){
								// debugger;
								if(item.auditItemId==this.tableData[x].auditItemId){
									item.i=false;
									break;
								}
							}
						});
						// debugger;
						this.selectlist.forEach(item=>{
							if(item.i==true){
								this.tableData.push(item);
								this.savelist.push(item.auditItemId);
								this.snlist.push(item.sn);
							}
						})
						console.log(this.selectlist)
						for(var i=0;i<this.savelist.length;i++){
							var entity2=new AuditTemplateDetailEditDto();
							entity2.auditTemplateId=0;
							entity2.auditPointId=this.selectedTreeKeys[0];
							entity2.auditItemId=this.savelist[i];
							entity2.sn=this.snlist[i];
							this.list.push(entity2);
							
							var entity3 = {};
							entity3.id = res[i].id;
							entity3.pid = this.selectedTreeKeys[0];
							entity3.name = res[i].name;
							entity3.auditItemTypeName = res[i].auditItemTypeName;
							// this.date.push(entity3);
							this.Staging.push(entity3);
						}
						this.tableData = _.orderBy(this.tableData, ['sn']);
						console.log(this.list);
						console.log(this.Staging);
					}

				})
			},
			//搜索框点击事件
			filteronchange() {
				this.pageNumber = 1;
				this.request.skipCount = 0;
				this.getData();
			},
			//行选择
			customRow: (record) => {
			    return {
			        on: {
			            click: () => {
			                _this.onSelect(record)
			            }
			        }
			    }
				
			},
			//多选
			SelectAll(record, rows, crows) {
			  if (record) {
			      //更改表格数据勾选状态
			      this.tableData.map(item => {
			          item.checked = true;
			      });
			      rows.forEach((item, index) => {
			          //判断是否存在
			          let u = this.selectedRowKey.find(t => t == item.auditItemId);
			          if (!u) {
			              this.selectedRowKey.push(item.auditItemId);
			              this.selectedRow.push(item);
			          }
			      });
			  } else {
			      //更改表格数据勾选状态
			      this.tableData.forEach((item, index) => {
			          item.checked = false;
			          this.selectedRowKey = [];
			          this.selectedRow = [];
			      });
			  }
			},
			// 重写复选框点击事件(不可删除)
			onSelect(record) {
			    record.checked = !record.checked;
			    if (record.checked) {
			        this.selectedRowKey.push(record.auditItemId);
					this.selectedRow.push(record);
			    } else {
			        this.selectedRowKey = _.difference(_this.selectedRowKey, [record.auditItemId]);
					this.selectedRow = _.difference(_this.selectedRow, [record]);
			    }
			},
			save() {
				if (this.name ==null||this.name=="") {
					abp.message.info(this.l("input_audittemplate"));
					return;
				}
				
				if (this.auditTemplateGroupId == undefined) {
					abp.message.info(this.l("Please_select_a_group"));
					return;
				}
				
				var entity1=new AuditTemplateEditoDto();
				if(this.isEdit){
					entity1.id=this.id;
				}
				entity1.auditTemplateGroupId = this.auditTemplateGroupId;
				entity1.name=this.name;
				entity1.isWalkAudit=this.isWalkAudit;
				entity1.enable=this.enable;
                entity1.barCodeList = this.selectbarcode;

				// var list=[];
				// for(var i=0;i<this.savelist.length;i++){
				// 	var entity2=new AuditTemplateDetailEditDto();
				// 	entity2.auditTemplateId=0;
				// 	entity2.auditPointId=this.selectedTreeKeys[0];
				// 	entity2.auditItemId=this.savelist[i];
				// 	entity2.sn=this.snlist[i];
				// 	list.push(entity2);
				// }

				var resultentity={
					auditTemplateEditoDto:entity1,
					auditTemplateDetailEditDto:this.list
				}
				console.log(resultentity)
				if(this.isclick) {
					this.isclick = false;
					this.AuditTemplateServiceProxy.createOrUpdate(
						resultentity
					).finally(() => {
						this.close()
					}).then(res => {
						this.isclick = true;
						this.success(true)
					})
				}
			
			},

			//删除数组中的元素
			arr(arr,res){
				for(var i = 0; i < arr.length; i++) {
					if(arr[i].auditItemId == res) {
						arr.splice(i, 1);
						break;
					}
				}
			},

			arr2(arr,res){
				for(var i = 0; i < arr.length; i++) {
					if(arr[i] == res) {
						arr.splice(i, 1);
						break;
					}
				}
			},


			delete1(){
				if (this.selectedRow.length <= 0) {
					abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
					return;
				}


				var idlist=[];
				this.selectedRow.forEach(res=>{
					if(res.isdata){
						idlist.push(res.id);
					}
				});

				this.$confirm(
						{
							title: this.l("ConfirmInformation"),
							content: this.l("the_selected_option"),
							cancelText: this.l('Cancel'),
							okText: this.l('Ok'),
							onOk: () => {
								this.AuditTemplateServiceProxy.batchDeleteDetail(idlist).then(res => {
									this.getData();
									this.getTree();
									this.selectedRow=[];
									this.selectedRowKey=[];
									this.$notification["success"]({
										message: this.l("SuccessfullyDeleted"),
									});
								})
							}
						})


			},
			
			getTreeData() {
			    this.isLoading = true;
			    this.AuditTemplateGroupServiceProxy.getPaged(
			        undefined
			    ).finally(() => {
				    this.isLoading = false;
				})
				.then((res) => {
				    this.treeDataList = JSON.parse(JSON.stringify(res));
				})
			},

            filterOption(input, option) {
				return (
					option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
				);
			},
			
		}
	}
</script>

<style>
	.input {
		width: 200px;
	}
</style>
